:local .hint {
  position: relative;
}

:local .hintShape {
  background: #666;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -45px;
  transform: rotate(45deg);
}

:local .hintMessage {
  color: #fff;
  text-align: center;
  white-space: pre-line;
  background: #666;
  width: 200px;
  min-height: 40px;
  padding: 3px;
  font-size: 12px;
  line-height: 34px;
  position: absolute;
  bottom: 35px;
  left: -42px;
}

:local .main {
  padding-top: 43px;
}

@media only screen and (max-width: 736px) {
  :local .main {
    padding-top: 0;
  }
}

:local .list {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 740px;
  min-height: 380px;
  margin: 0 auto;
  padding: 30px 40px;
}

@media only screen and (max-width: 736px) {
  :local .list {
    width: 100%;
    height: auto;
    padding: 15px;
  }
}

:local .li {
  float: left;
  align-items: center;
  width: 329px;
  height: 120px;
  margin-top: 20px;
  padding-left: 20px;
  display: flex;
}

:local .li:hover {
  background-color: #ededed;
}

@media only screen and (max-width: 736px) {
  :local .li {
    float: none;
    width: 100%;
    height: auto;
    min-height: 100px;
    margin-top: 0;
    padding: 10px;
  }
}

:local .liDisabled {
  composes: li;
}

:local .liDisabled:hover {
  background-color: #fff;
}

:local .liDisabled:hover {
  cursor: not-allowed;
  background-color: #ededed;
}

:local .dt {
  color: #3498db;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

:local .icon {
  color: #000;
  padding-bottom: 3px;
  padding-right: 6px;
}

:local .text {
  padding-top: 10px;
  font-size: 13px;
}

:local .itemWrap {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

:local .itemDisabled {
  composes: itemWrap;
}

:local .itemDisabled .definition dt {
  color: #aaa;
}
