:local .topComment {
  padding: 0 50px;
}

:local .mainComment {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
}

:local .subComment {
  font-size: 16px;
}

:local .commentContent {
  padding-top: 10px;
}

@media only screen and (max-width: 736px) {
  :local .topComment {
    margin-top: 30px;
  }

  :local .subComment {
    margin-top: 30px;
  }
}
