:local .showModal {
  color: #666;
  border: 1px solid #ccc;
  margin-left: auto;
  font-weight: bold;
}

:local .modalBody {
  gap: 20px;
  margin-bottom: 20px;
  display: grid;
}

:local .modalBody .checkbox {
  margin-bottom: -30px;
}

:local .modalBody .checkboxText {
  padding-left: 25px;
}

:local .modalBody .dateField {
  gap: 0 10px;
  width: 100%;
  display: flex;
}

:local .modalBody .dateField .selectDate {
  width: 400px;
}

:local .modalBody .dateField .selectDate :global .rdtPicker {
  position: relative;
}

@media only screen and (max-width: 736px) {
  :local .modalBody .dateField .selectDate {
    width: 100%;
  }
}

:local .modalBody .dateField .endText {
  min-width: 35px;
  margin-top: 36px;
}

:local .modalBody .mailField {
  gap: 20px;
  display: grid;
}

:local .modalBody .mailField .sendCount {
  font-size: 14px;
}

:local .modalBody .mailField .selectMail {
  width: 400px;
  font-size: 14px;
}

@media only screen and (max-width: 736px) {
  :local .modalBody .mailField .selectMail {
    width: 100%;
  }

  :local .modalFooter {
    padding: 0 15px;
  }
}

:local .modalFooter .cancelButton {
  width: 200px;
}

:local .modalFooter .sendButton {
  width: 200px;
  margin-left: 20px;
}
