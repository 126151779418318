:local .wrap {
  z-index: 1000;
  position: fixed;
  bottom: 150px;
  right: 50px;
}

:local .wrap:hover .message {
  display: block;
}

:local .circle {
  composes: sk-circle from global;
  text-align: center;
  vertical-align: middle;
  color: #3498db;
  display: table-cell;
  width: 80px !important;
  height: 80px !important;
  margin: 0 !important;
}

:local .circle :global(.sk-circle-dot:before) {
  background-color: #3498db !important;
}

:local .circleDone {
  composes: circle;
}

:local .circleDone :global(.sk-circle-dot:before) {
  animation-play-state: paused !important;
}

:local .circleError {
  composes: circleDone;
  color: #e61345;
}

:local .circleDone + .message {
  width: 200px;
  display: block;
}

:local .message {
  word-break: break-all;
  background: #fff;
  border: 1px solid #3498db;
  width: 300px;
  padding: 15px 20px 10px 10px;
  display: none;
  position: absolute;
  bottom: 100px;
  right: 0;
}

:local .message:before {
  content: "";
  background: #fff;
  border-bottom: 1px solid #3498db;
  border-right: 1px solid #3498db;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  bottom: -11px;
  right: 30px;
  transform: rotate(45deg);
}

:local .close {
  position: absolute;
  top: 5px;
  right: 5px;
}
