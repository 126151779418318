:local .main {
  padding-top: 43px;
}

:local .section {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 740px;
  min-height: 260px;
  margin: 0 auto;
  padding: 30px 40px;
}

:local .mainMessage {
  color: #3498db;
  text-align: center;
  padding-top: 55px;
  font-size: 20px;
  font-weight: 500;
}

:local .subMessage {
  text-align: center;
  padding-top: 35px;
  font-size: 13px;
}
