:local .switch {
  composes: u-ta-l from global;
  display: inline-block;
  position: relative;
}

:local .balloon {
  z-index: 3000;
  word-break: keep-all;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #3498db;
  padding: 15px;
  position: absolute;
  top: 50px;
}

:local .balloon.center {
  left: 50%;
  transform: translate(-50%);
}

:local .balloon.right {
  left: 100%;
  transform: translate(-100%);
}

:local .balloon:after {
  content: "";
  background: #fff;
  border-top: 1px solid #3498db;
  border-left: 1px solid #3498db;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: -5.6px;
  transform: rotate(45deg);
}

:local .balloon.center:after {
  left: calc(50% - 5.6px);
}

:local .balloon.right:after {
  right: 10px;
}

:local .li {
  margin: 7px;
  font-size: 13px;
}

:local .li svg {
  color: #aaa;
  margin-right: 8px;
}

:local .center {
  composes: balloon;
}

:local .right {
  composes: balloon;
}
