:local .item {
  color: #333;
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
}

:local .item:first-child {
  border-radius: 4px 4px 0 0;
}

:local .item:last-child {
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
}

:local .item:only-child {
  border-radius: 4px;
}

:local .item .arrow {
  margin-left: auto;
}

:local a.item {
  text-decoration: none;
}

:local a.item:hover {
  background: #ededed;
}

:local .title {
  composes: item;
  background-color: #fafafa;
  justify-content: space-between;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: bold;
}

:local .group {
  margin-top: 20px;
}

:local .group:first-child {
  margin-top: 0;
}

@media only screen and (max-width: 736px) {
  :local .item {
    padding: 10px 15px;
  }

  :local .arrow {
    margin-top: -10px;
    position: absolute;
    top: 50%;
    right: 5px;
  }
}
