:local .hint {
  position: relative;
}

:local .hintShape {
  background: #666;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -30px;
  left: 7px;
  transform: rotate(45deg);
}

:local .hintMessage {
  color: #fff;
  text-align: center;
  white-space: pre-line;
  background: #666;
  width: 112px;
  min-height: 40px;
  padding: 3px;
  font-size: 12px;
  position: absolute;
  bottom: 20px;
  left: -42px;
}
