:local .formField {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-width: 960px;
  min-height: 380px;
  margin: 44px auto 0;
  padding-bottom: 45px;
}

@media only screen and (max-width: 736px) {
  :local .formField {
    margin-top: 30px;
    padding-bottom: 10px;
  }
}

:local .title {
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  align-items: center;
  height: 48px;
  padding: 10px 10px 10px 20px;
  display: flex;
}

:local .category {
  padding: 30px;
}

@media only screen and (max-width: 736px) {
  :local .category {
    padding: 10px;
  }
}

:local .categoryTitle {
  font-weight: bold;
  display: flex;
}

:local .categoryNumber {
  width: 20px;
}

:local .questions {
  margin-top: 20px;
}

:local .question {
  margin-top: 3px;
}

:local .childQuestions {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}

:local .childQuestion {
  margin-top: 3px;
}

:local .buttonWrap {
  background-color: #fff;
  border: 1px solid #979797;
  border-radius: 3px;
  width: 170px;
  height: 40px;
  margin: 10px auto 0;
}

:local .backButton {
  color: #666;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

:local .select {
  justify-content: center;
  align-items: center;
  width: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  display: flex;
}
