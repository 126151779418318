:local .contentsWrap {
  padding-top: 59px;
}

@media only screen and (max-width: 736px) {
  :local .contentsWrap {
    padding-top: 30px;
  }
}

:local .leftButtons {
  float: left;
  width: 50%;
  height: 59px;
}

@media only screen and (max-width: 736px) {
  :local .leftButtons {
    float: none;
    width: 100%;
    height: auto;
  }
}

:local .select {
  width: 100%;
  margin-bottom: 23px;
}

:local .select span > div span {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
  overflow: hidden;
}

@media only screen and (max-width: 736px) {
  :local .select {
    margin-bottom: 10px;
  }
}

:local .table {
  composes: m-table-list from global;
  composes: m-table-fixed from global;
}

:local .table td {
  padding: 10px 10px 5px !important;
}

:local .staffCodeColumn {
  width: 116px;
}

:local .nameColumn {
  width: 161px;
}

:local .employmentStatusColumn {
  width: 122px;
}

:local .groupColumn {
  width: 166px;
}

:local .statusColumn {
  min-width: 48px;
}

:local .statusHeader {
  width: 2.5em;
  margin: 0 auto;
}

:local .highStressColumn {
  width: 87px;
}

:local .row {
  height: 72px;
}

:local .notable {
  color: red;
}

:local .notFinished {
  color: red;
}

:local .surveyPeriod {
  text-align: center;
  margin-top: -20px;
  margin-bottom: 10px;
}

:local .icon {
  cursor: pointer;
}
