:local .base {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 100px;
  margin-bottom: 30px;
  padding: 0;
}

:local .heading {
  background: #fafafa;
  border-bottom: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px 13px;
  font-size: 20px;
  display: flex;
}

:local .lead {
  color: #276e9f;
  text-align: center;
  margin: 50px 0;
  font-size: 24px;
  font-weight: bold;
}

@media only screen and (max-width: 736px) {
  :local .lead {
    margin: 20px 0;
    font-size: 18px;
  }
}

:local .description {
  text-align: center;
  margin: 30px 0;
  font-size: 14px;
}

:local .description p {
  margin: 10px 0;
}

@media only screen and (max-width: 736px) {
  :local .description {
    margin: 15px 0;
  }

  :local .description p {
    margin: 5px 0;
  }
}

:local .notes {
  text-align: left;
  background-color: #e1e1e1;
  margin: 30px 0;
  padding: 20px;
}

@media only screen and (max-width: 736px) {
  :local .notes {
    padding: 15px;
  }
}

:local .leadIcon {
  color: #999;
  vertical-align: text-bottom;
  white-space: nowrap;
  padding-right: 5px;
}

:local .inner {
  padding: 30px;
}

@media only screen and (max-width: 736px) {
  :local .inner {
    padding: 15px;
  }
}

:local .buttons {
  justify-content: center;
  align-items: center;
  margin: 100px 0 50px;
  display: flex;
}

:local .buttons > div {
  margin: 0 10px;
}

@media only screen and (max-width: 736px) {
  :local .buttons {
    flex-wrap: wrap;
    margin: 50px 0 25px;
    display: flex;
  }

  :local .buttons > div {
    margin: 0 0 10px;
  }
}

:local .button {
  color: #666;
  border: 1px solid #666;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

@media only screen and (max-width: 736px) {
  :local .button {
    width: auto;
  }
}

:local .label {
  margin: 15px 0;
  font-size: 15px;
  font-weight: bold;
}

:local .text {
  margin: 10px 0;
  font-size: 14px;
}

:local .toggles {
  border-radius: 4px;
  margin: 40px 0;
  display: inline-flex;
}

:local .toggles div:first-child {
  border-radius: 4px 0 0 4px;
}

:local .toggles div:last-child {
  border-radius: 0 4px 4px 0;
}

@media only screen and (max-width: 736px) {
  :local .toggles {
    display: flex;
  }
}

:local .toggle {
  cursor: pointer;
  text-align: center;
  border: 1px solid #aaa;
  width: 200px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
}

:local .toggle:not(:first-child) {
  border-left: none;
}

@media only screen and (max-width: 736px) {
  :local .toggle {
    width: 50%;
  }
}

:local .toggleOn {
  composes: toggle;
  color: #fff;
  background-color: #3498db;
  border: 1px solid #3498db;
}

:local .toggleOff {
  composes: toggle;
  color: #aaa;
}

:local .line {
  border-top: 1px solid #ccc;
  margin: 20px 0 40px;
}
