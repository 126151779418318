.c-dropdown .dd-menu .dd-menu-toggle:hover {
  color: #3498db;
  cursor: pointer;
}

.c-dropdown .dd-menu .dd-menu-items {
  margin: 0;
  position: relative;
  top: 5px;
  left: 15px;
}

.c-dropdown .dd-menu .dd-menu-items .dd-menu-items-button {
  z-index: 100;
  background-color: #fff;
  border-radius: 4px;
  width: 250px;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 4px 18px #0000004d;
}

.c-dropdown .dd-menu .dd-menu-items .dd-menu-items-button button {
  text-align: left;
  background-color: #fff;
  border: none;
  width: 100%;
  padding: 15px;
  display: block;
}

.c-dropdown .dd-menu .dd-menu-items .dd-menu-items-button button.clickable:hover {
  cursor: pointer;
  background-color: #ededed;
  font-weight: bold;
}
