:local .headerStickyWrap {
  z-index: 101;
  position: sticky;
  top: 0;
}

@media only screen and (max-width: 736px) {
  :local .headerStickyWrap {
    position: relative;
  }
}

:local .base {
  color: #000;
  z-index: 100;
  background-color: #fff;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 13px 20px;
  font-size: 14px;
  display: flex;
  position: relative;
  box-shadow: 0 1px 3px #ddd;
}

:local .logo {
  color: #007cff;
  align-items: baseline;
  margin-right: auto;
  display: flex;
}

:local .logoText {
  color: #a8a8a8;
  margin-left: 4px;
  font-size: 11px;
}

:local .base a {
  color: #000;
}

:local .base a:hover {
  color: #3498db;
}

:local .brand {
  height: 20px;
}

:local .item:not(:first-child) {
  padding-left: 10px;
}

:local .item:not(:last-child):after {
  content: "|";
  padding-left: 10px;
}

:local .item svg {
  color: #999;
}

:local .client {
  composes: item;
  align-items: center;
  display: flex;
}

:local .applicableOffice {
  composes: item;
  align-items: center;
  display: flex;
}

:local .user {
  composes: item;
}

:local .link {
  color: #aaa;
  margin-right: auto;
  text-decoration: none;
}

:local .link:hover {
  color: #3498db;
  cursor: pointer;
}

:local .email {
  margin-left: 8px;
}

:local .help {
  composes: item;
  padding-top: 1px;
}

:local .help svg {
  color: #3498db;
  margin-right: 5px;
  top: 1px;
}

:local .notVerified {
  color: #e61345;
  margin-top: -13px;
  margin-bottom: -13px;
  margin-right: 10px;
  font-size: 13px;
}

@media only screen and (max-width: 736px) {
  :local .brand {
    width: 120px;
  }

  :local .email {
    display: none;
  }

  :local .pulldown {
    display: none;
  }

  :local .client {
    display: none;
  }

  :local .trial {
    min-width: 130px;
    padding: 0 5px;
    font-size: 12px;
  }

  :local .notVerified {
    display: none;
  }
}
