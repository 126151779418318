:local .mainSearchFields {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

:local .searchForm {
  composes: searchForm from "jbc-front/components/SearchForm.scss";
}

:local .officesSelectBox {
  width: 400px;
}

:local .buttons {
  position: relative;
}

:local .buttons > div {
  position: absolute;
  top: 10px;
}

@media only screen and (max-width: 736px) {
  :local .mainSearchFields > div:not(:first-child) {
    margin: 10px 0 0;
  }
}
