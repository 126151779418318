:local .wrap {
  margin-bottom: 30px;
  position: relative;
}

:local .errorWrap {
  composes: wrap;
  color: #e61345;
  background-color: #f9e8ec;
}

:local .inner {
  width: 960px;
  margin: 0 auto;
  padding: 25px;
}

:local .dates {
  margin-top: 10px;
  display: flex;
}

:local .dates dl {
  margin-left: 20px;
  display: flex;
}

:local .dates dl:first-child {
  margin-left: 0;
}

:local .title {
  font-weight: bold;
}

:local .list {
  margin-top: 10px;
  list-style-type: disc;
  list-style-position: inside;
}

:local .close {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

:local .waiting {
  align-items: center;
  display: flex;
}

:local .loading :global(.sk-circle) {
  margin: 0 auto;
}

:local .loading :global(.sk-circle .sk-child:before) {
  background-color: #3498db;
}

:local .info {
  margin-left: 30px;
}

@media only screen and (max-width: 736px) {
  :local .wrap {
    margin: 0 -15px;
  }

  :local .inner {
    width: 100%;
    padding: 15px 30px 15px 15px;
  }

  :local .dates {
    display: block;
  }

  :local .dates dl {
    margin-left: 0;
    display: flex;
  }

  :local .close {
    top: 12px;
    right: 10px;
  }

  :local .info {
    margin-left: 20px;
  }
}
