:local .main {
  padding-top: 41px;
}

:local .table {
  composes: m-table-list from global;
  composes: m-table-fixed from global;
  margin-top: 52px;
}

:local .table td {
  padding: 10px 10px 5px !important;
}

:local .table td .withLineThrough {
  text-decoration: line-through;
}

:local .header {
  height: 40px;
}

:local .createdAtColumn {
  width: 119px;
}

:local .endDateColumn {
  width: 209px;
}

:local .formColumn {
  width: 282px;
}

:local .mailColumn {
  width: 294px;
}

:local .deleteColumn {
  width: 42px;
}

:local .row {
  height: 50px;
}

:local .icon {
  color: #909090;
  cursor: pointer;
}

:local .confirm {
  color: #d0021b;
}
