:local .marginSidebar {
  margin-left: 50px;
  overflow-y: auto;
}

:local .main {
  background: #f1f1f1;
  position: relative;
}

@media only screen and (max-width: 736px) {
  :local .marginSidebar {
    margin-left: 0;
  }
}

:local .base {
  color: #000;
  z-index: 100;
  background-color: #fff;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 13px 20px;
  font-size: 14px;
  display: flex;
  position: relative;
  box-shadow: 0 1px 3px #ddd;
}

.zEWidget-launcher {
  bottom: 60px !important;
}

@media only screen and (max-width: 736px) {
  .zEWidget-launcher {
    right: -5px;
    bottom: 5px !important;
  }
}
