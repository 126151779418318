:local .contentsWrap {
  padding-top: 73px;
}

:local .wrap {
  max-width: 950px;
  margin: 0 auto;
}

:local .name {
  text-align: center;
  color: #3498db;
  width: 226px;
  height: 24px;
  margin-left: 11px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

:local .name p {
  color: #3498db;
}

:local .implementationCondition {
  letter-spacing: normal;
  color: #333;
  height: 53px;
  padding-top: 31px;
  padding-left: 3px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: normal;
}

:local .searchImplementStatusField {
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  display: flex;
}

@media only screen and (max-width: 736px) {
  :local .searchImplementStatusField {
    gap: 5px;
  }
}

:local .selectType {
  margin-top: 7px;
}

:local .selectForm {
  padding-top: 15px;
  display: flex;
}

@media only screen and (max-width: 736px) {
  :local .selectForm {
    flex-wrap: wrap;
  }
}

:local .results {
  width: 357px;
  margin-left: 33px;
  display: flex;
}

@media only screen and (max-width: 736px) {
  :local .results {
    width: 100%;
    margin-left: 0;
  }
}

:local .icon {
  color: #979797;
}

:local .select {
  width: 560px;
  height: 36px;
}

:local .select span > div span {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
  overflow: hidden;
}

@media only screen and (max-width: 736px) {
  :local .select {
    margin-bottom: 10px;
  }
}

:local .surveySummary {
  height: 215px;
  margin-top: 15px;
}

:local .noSurvey {
  height: 100px;
  position: relative;
}

:local .noSurvey p {
  text-align: center;
  width: 100%;
  font-size: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

:local .surveyPeriod {
  height: 50px;
  padding-left: 30px;
  position: relative;
}

:local .surveyPeriod p {
  vertical-align: middle;
  font-size: 13px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

:local .summaryTable {
  height: 135px;
}

:local .col {
  float: left;
  text-align: center;
  border-right: 2px solid #e8e8e8;
  width: 25%;
  height: 125px;
  margin-bottom: 10px;
}

:local .colRight {
  float: left;
  text-align: center;
  width: 25%;
  height: 125px;
  margin-bottom: 10px;
}

:local .colTitle {
  margin-top: 32px;
  font-size: 13px;
}

:local .colCount {
  color: #3498db;
  margin-top: 15px;
  font-size: 16px;
}

:local .largeButton {
  color: #3498db;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 98px;
  display: flex;
}

:local .personalResultButton {
  border-radius: 3px;
  width: 170px;
  margin-left: 17px;
}

@media only screen and (max-width: 736px) {
  :local .personalResultButton {
    margin-left: 0;
  }
}

:local .groupResultButton {
  width: 170px;
  margin-left: 17px;
}

@media only screen and (max-width: 736px) {
  :local .groupResultButton {
    margin-left: 10px;
  }
}

:local .button {
  color: #666;
  border-radius: 3px;
  font-weight: bold;
  border: 1px solid #ccc !important;
}

:local .ableButton:hover {
  border: 1px solid #3498db !important;
}

:local .ableButton:hover .icon {
  color: #3498db;
}
