:local .main {
  padding-top: 41px;
}

:local .alignRight {
  text-align: right;
}

:local .createMailButton {
  min-width: 152px;
}

:local .table {
  composes: m-table-list from global;
  composes: m-table-fixed from global;
  margin-top: 52px;
}

:local .table td {
  padding: 10px 10px 5px !important;
}

:local .table td .withLineThrough {
  text-decoration: line-through;
}

:local .header {
  height: 40px;
}

:local .buttons {
  width: 126px;
}

:local .copyColumn {
  width: 42px;
}

:local .editColumn {
  width: 42px;
}

:local .deleteColumn {
  width: 42px;
}

:local .row {
  height: 50px;
}

:local .icon {
  color: #909090;
  cursor: pointer;
}

:local .noteWrap {
  display: inline-block;
  position: relative;
}

:is(:local .noteWrap:hover .note, :local .noteWrap:hover .mainOfficeNote, :local .noteWrap:hover .sameNote) {
  display: block;
}

:local .note {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background: #666;
  border-radius: 3px;
  width: auto;
  padding: 15px 20px;
  font-size: 12px;
  font-weight: bold;
  display: none;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

:local .note:after {
  content: "";
  background: #666;
  width: 15px;
  height: 15px;
  margin-left: -7px;
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: rotate(45deg);
}

:local .confirm {
  color: #d0021b;
}
