:local .contentsWrap {
  padding-top: 27px;
}

:local .searchEmploymentStatusField {
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  display: flex;
}

@media only screen and (max-width: 736px) {
  :local .searchEmploymentStatusField {
    gap: 5px;
  }
}

:local .buttonArea {
  justify-content: flex-end;
  margin-bottom: 20px;
  display: flex;
}

:local .buttonArea a {
  margin-left: 10px;
}

:local .buttonArea a:first-child {
  margin-left: 0;
}

:local .buttonArea .buttonDl {
  margin-left: 0;
  margin-right: auto;
}

:local .buttonList {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

:local .buttonRight {
  display: flex;
}

:local .staffcodeColumn {
  flex-basis: 100px;
  width: 100px;
}

:local .nameColumn {
  flex-basis: 172px;
  width: 172px;
}

:local .officeColumn {
  flex-basis: 146px;
  width: 146px;
}

:local .employmentStatusColumn {
  flex-basis: 108px;
  width: 108px;
}

:local .groupColumn {
  flex-basis: 146px;
  width: 146px;
}

:local .positionColumn {
  flex-basis: 108px;
  width: 108px;
}

:local .joinedAtColumn {
  flex-basis: 130px;
  width: 130px;
}

:local .tr {
  height: 50px;
}

:local .arrowText {
  white-space: nowrap;
}

:local .statusDiv {
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

:local .statusDiv:focus {
  outline: 0;
}

:local .pulldown {
  position: absolute;
  right: 14px;
}

:local .table {
  composes: m-table-list from global;
  composes: m-table-fixed from global;
}

:local .table td {
  padding: 10px 10px 5px !important;
}

:local .memo :global(.u-cur-pointer) {
  background-repeat: no-repeat;
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
}

:local .errorMessage {
  color: #e61345;
  text-align: center;
  margin: 30px 0 -10px;
}

@media only screen and (max-width: 736px) {
  :local .buttonArea {
    display: block;
  }

  :local .buttonArea a {
    margin-left: 0;
    margin-right: 10px;
  }

  :local .buttonDl {
    margin-bottom: 10px;
  }

  :local .searchBox {
    width: 100%;
  }
}
