:local .showModal {
  color: #666;
  border: 1px solid #ccc;
  margin-left: auto;
  font-weight: bold;
}

:local .showModal:hover .ableIcon {
  color: #3498db;
}

:local .showModal .icon {
  color: #979797;
  transform: translateY(-10%);
}

:local .modalBody {
  gap: 20px;
  margin-bottom: 20px;
  display: grid;
}

:local .modalBody .sendCount {
  font-size: 16px;
}

:local .modalBody .mailField {
  width: 400px;
}

@media only screen and (max-width: 736px) {
  :local .modalBody .mailField {
    width: 100%;
  }
}

:local .modalFooter .cancelButton {
  width: 200px;
}

:local .modalFooter .sendButton {
  width: 200px;
  margin-left: 20px;
}

@media only screen and (max-width: 736px) {
  :local .modalFooter {
    padding: 0 15px;
  }
}
