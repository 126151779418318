:local .deleteButton {
  display: inline-block;
  position: relative;
}

:is(:local .deleteButton:hover .note, :local .deleteButton:hover .noteLarge, :local .deleteButton:hover .mainOfficeNote, :local .deleteButton:hover .sameNote) {
  display: block;
}

:local .deleteButton .icon {
  color: #909090;
}

:local .deleteButton .note {
  text-align: center;
  white-space: nowrap;
  color: #fff;
  background: #666;
  border-radius: 3px;
  width: auto;
  padding: 15px 20px;
  font-size: 12px;
  font-weight: bold;
  display: none;
  position: absolute;
  top: -60px;
  right: -18px;
}

:local .deleteButton .note:after {
  content: "";
  background: #666;
  width: 15px;
  height: 15px;
  margin-left: -7px;
  position: absolute;
  bottom: -7px;
  right: 20px;
  transform: rotate(45deg);
}

:local .modal {
  min-width: 500px;
}

@media only screen and (max-width: 736px) {
  :local .modal {
    min-width: auto;
  }
}

:local .modalBody {
  gap: 20px;
  margin-bottom: 20px;
  display: grid;
}

:local .modalBody .surveyItem {
  gap: 3px;
  display: grid;
}

:local .modalBody .employeeName {
  max-width: 400px;
}

:local .modalBody .warningText {
  color: red;
}

:local .modalBody .mailField {
  width: 400px;
}

@media only screen and (max-width: 736px) {
  :local .modalBody .mailField {
    width: 100%;
  }
}

:local .modalFooter .cancelButton {
  width: 200px;
}

:local .modalFooter .sendButton {
  width: 200px;
  margin-left: 20px;
}

@media only screen and (max-width: 736px) {
  :local .modalFooter {
    padding: 0 15px;
  }
}
