@media only screen and (max-width: 736px) {
  :local .sortingFieldsWithResult > div {
    flex-wrap: wrap;
    justify-content: center;
  }

  :local .sortingFieldsWithResult > div:before {
    content: none;
  }

  :local .sortingFieldsWithResult > div > div {
    width: 100%;
    margin-top: 10px;
  }

  :local .sortingFieldsWithResult > div > div:last-child > div + div {
    margin-left: 10px;
  }
}
