:local .addButton {
  margin: 5px;
}

:local .disabledButton {
  color: #666;
  background-color: #fff;
  border: 1px solid #666;
  margin: 5px;
}
