:local .main {
  padding-top: 41px;
}

:local .table {
  composes: m-table-list from global;
  composes: m-table-fixed from global;
  margin-top: 52px;
  width: 100% !important;
}

:local .table td {
  padding: 0 !important;
}

:local .table td .withLineThrough {
  text-decoration: line-through;
}

:local .header {
  height: 40px;
}

:local .formNameColumn {
  text-align: left !important;
  padding-left: 34px !important;
  font-size: 14px !important;
}

:local .row {
  cursor: pointer;
  height: 50px;
  padding-left: 24px;
  text-align: left !important;
}

:local .row:hover {
  background-color: #ededed;
}

:local .formTitle {
  padding-left: 34px;
}
