:local .searchRoleField {
  flex-wrap: wrap;
  margin-top: 7px;
  display: flex;
}

:local .role {
  width: 82px;
}

:local .main {
  padding-top: 41px;
}

:local .map {
  font-size: 13px;
  font-weight: 500;
}

:local .addUser {
  padding-bottom: 22px;
}

:local .editButton {
  font-weight: 500;
}

@media only screen and (max-width: 736px) {
  :local .editButton {
    position: relative !important;
    top: 0 !important;
  }

  :local .buttonWrap {
    position: relative !important;
    top: 0 !important;
  }
}

:local .cancelButton {
  margin-left: 13px;
}

:local .table {
  composes: m-table-list from global;
  composes: m-table-fixed from global;
}

:local .table td {
  padding: 10px 10px 5px !important;
}

:local .table td .withLineThrough {
  text-decoration: line-through;
}

@media only screen and (max-width: 736px) {
  :local .table {
    min-height: 150px;
  }
}

:local .header {
  height: 40px;
}

:local .roleTypeColumn {
  width: 183px;
}

:local .staffCodeColumn {
  width: 227px;
}

:local .nameColumn {
  width: 196px;
}

:local .emailColumn {
  width: 354px;
}

:local .emailColumnEditing {
  width: 311px;
}

:local .deleteColumn {
  width: 42px;
}

:local .row {
  height: 50px;
}

:local .select {
  width: 130px;
  top: -2px;
  margin: 0 auto !important;
}

:local .disabled {
  box-shadow: none;
  color: #dedede;
  cursor: not-allowed;
  width: auto;
  min-width: auto;
  height: 30px;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 30px;
  display: inline-block;
  position: relative;
}

:local .disabled:hover .disabledReason {
  display: block;
}

:local .icon {
  cursor: pointer;
}
