:local .sendFormArea {
  display: block;
}

:local .sendFormArea .showModal {
  width: 180px;
  margin: 0 auto;
}

:local .sendFormArea .count {
  text-align: center;
  width: 100%;
  margin-top: 10px;
}

@media only screen and (max-width: 736px) {
  :local .sendFormArea {
    text-align: center;
  }
}

:local .modal {
  min-width: 500px;
}

@media only screen and (max-width: 736px) {
  :local .modal {
    min-width: auto;
  }
}

:local .modalBody {
  gap: 20px;
  margin-bottom: 20px;
  display: grid;
}

:local .modalBody .sendCount {
  font-size: 16px;
}

:local .modalBody .surveyItem {
  gap: 3px;
  display: grid;
}

:local .modalBody .mailField {
  width: 400px;
}

@media only screen and (max-width: 736px) {
  :local .modalBody .mailField {
    width: 100%;
  }
}

:local .modalFooter .cancelButton {
  width: 200px;
}

:local .modalFooter .sendButton {
  width: 200px;
  margin-left: 20px;
}

@media only screen and (max-width: 736px) {
  :local .modalFooter {
    padding: 0 15px;
  }
}
