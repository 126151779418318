:local .jbcidInfo {
  text-align: center;
  width: 700px;
  margin: 0 auto;
  padding: 40px 0 0;
}

:local .jbcidTitle {
  color: #4a4a4a;
  padding: 0 40px 10px;
  font-size: 24px;
  font-weight: bold;
}

:local .jbcidSubTitle {
  color: #4a4a4a;
  border-bottom: 1px solid #9b9b9b;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: bold;
}

:local .jbcidLead {
  color: #4a4a4a;
  text-align: center;
  margin: 20px auto 0;
  padding: 0 25px;
  font-size: 14px;
}

:local .loginWrap {
  background-color: #f1f1f1;
  justify-content: center;
  align-items: center;
  display: flex;
}

:local .loginBox {
  background-color: #fff;
  border-radius: 4px;
  width: 400px;
  margin-top: 50px;
  padding: 20px 30px 30px;
  box-shadow: 0 1px 2px #0000001a;
}

:local .logo {
  color: #007cff;
}

:local .logoBox {
  align-items: baseline;
  width: 230px;
  margin: 0 auto;
  display: flex;
}

:local .logoText {
  color: #a8a8a8;
  width: 100px;
  margin-left: 4px;
  font-size: 11px;
}

:local .brand {
  width: 130px;
  height: 26px;
}

:local .loginTitle {
  text-align: center;
  padding-top: 0 !important;
  font-size: 20px !important;
}

:local .loginField {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-top: 5px;
  padding: 30px 0;
}

:local .loginCollapseWrap {
  height: 84px;
  margin: 0 0 20px;
  transition: height .4s;
  overflow: hidden;
}

:local .loginCollapseWrap > div {
  margin: 0;
}

:local .loginCollapseWrap > div:not(:last-of-type) {
  margin: 0 0 20px;
}

:local .loginCid {
  text-align: center;
  display: block;
}

:local .loginButton {
  text-align: center;
  margin-top: 30px;
}

:local .loginForgot {
  cursor: pointer;
  margin-top: 20px;
  font-size: 12px;
  display: block;
}

:local .commonLoginField {
  margin-top: 30px;
}

@media only screen and (max-width: 736px) {
  :local .loginBox {
    width: 100%;
    margin-top: 30px;
    padding: 10px 20px 15px;
  }

  :local .jbcidInfo {
    text-align: left;
    width: 100%;
    padding: 30px 0 0;
  }

  :local .jbcidTitle {
    padding: 0 0 10px;
    font-size: 20px;
  }

  :local .jbcidLead {
    text-align: left;
    width: 100%;
    margin: 20px auto 0;
    padding: 0 10px;
    font-size: 14px;
  }

  :local .spnone {
    display: none;
  }
}
