:local .title {
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  align-items: center;
  height: 48px;
  padding: 10px 10px 10px 20px;
  display: flex;
}

:local .provideButton {
  justify-content: center;
  align-items: center;
  width: 200px;
  min-width: 200px;
  height: 30px;
  margin-left: auto;
  padding: 0;
  font-size: 13px;
  display: flex;
}

:local .chart {
  padding-top: 46px;
}

:local .chartTitle {
  text-align: center;
  color: #333;
  padding-bottom: 20px;
  font-weight: bold;
}

:local .description {
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  display: flex;
}

:local .notableDescription {
  display: flex;
}

:local .rectangle {
  background-color: #ffcd52;
  width: 20px;
  height: 20px;
  margin-top: -2px;
}

:local .notableZone {
  width: 92px;
  padding-left: 10px;
  font-size: 12px;
}

:local .chartInfo {
  padding-left: 10px;
  font-size: 11px;
}

:local .result {
  padding: 0 70px;
}

:local .table {
  composes: m-table-list from global;
  composes: m-table-fixed from global;
  padding-bottom: 50px;
}

:local .itemColumn {
  width: 80%;
  font-size: 14px !important;
}

:local .scoreColumn {
  width: 20%;
  font-size: 14px !important;
}

:local .tr {
  height: 64px;
}

:local .th {
  background-color: #fff !important;
  padding: 0 !important;
}

:local .item {
  width: 80%;
  vertical-align: center;
  float: left;
  color: #333;
  padding-left: 20px;
  font-size: 13px;
  font-weight: bold;
  text-align: left !important;
  font-size: 14px !important;
}

:local .score {
  width: 20%;
  vertical-align: center;
  float: left;
  font-size: 14px !important;
}

:local .notableCheck {
  border: 2px solid #d0021b;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 29px;
  display: flex;
  font-size: 14px !important;
}

:local .notableMark {
  width: 20px;
}

:local .notableCheckItem {
  float: left;
  padding-top: 3px;
  padding-left: 3px;
  font-size: 14px !important;
}

:local .notableSentence {
  float: left;
  color: #d0021b;
}

:local .comments {
  text-align: left;
  padding: 14px 30px 30px;
  font-size: 14px !important;
}

:local .commentContent {
  padding-top: 10px;
}

@media only screen and (max-width: 736px) {
  :local .chart {
    padding-top: 0;
  }

  :local .row {
    flex-direction: column;
    justify-content: start;
    align-items: center;
    display: flex;
  }

  :local .radar {
    margin-top: 30px;
  }

  :local .description {
    flex-direction: column;
  }

  :local .chartInfo {
    text-align: center;
    margin-top: 10px;
  }

  :local .result {
    padding: 0 10px;
  }

  :local .table {
    width: 100% !important;
  }

  :local .notableSentence {
    width: 220px;
  }
}
