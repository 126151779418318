:local .contentsWrap {
  padding-top: 59px;
}

@media only screen and (max-width: 736px) {
  :local .contentsWrap {
    padding-top: 30px;
  }
}

:local .select {
  width: 50%;
  margin-bottom: 28px;
}

:local .select span > div span {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
  overflow: hidden;
}

@media only screen and (max-width: 736px) {
  :local .select {
    width: 100%;
  }
}

:local .resultField {
  background-color: #fff;
  width: 960px;
  padding-top: 17px;
  padding-bottom: 32px;
}

@media only screen and (max-width: 736px) {
  :local .resultField {
    width: 100%;
    padding: 10px 0;
  }
}

:local .tags {
  margin-top: 15px;
  padding-bottom: 24px;
  padding-left: 30px;
  display: flex;
}

:local .tags :first-child {
  margin-left: 0;
}

@media only screen and (max-width: 736px) {
  :local .tags {
    margin-top: 0;
    padding: 10px;
  }
}

:local .defaultTag {
  letter-spacing: normal;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 48px;
  margin-left: 6px;
  padding: 10px 18px 12px;
  display: flex;
}

@media only screen and (max-width: 736px) {
  :local .defaultTag {
    flex-basis: 100%;
    padding: 10px 5px;
  }
}

:local .selectedTag {
  composes: defaultTag;
  color: #3498db;
  border: 1px solid #3498db;
  font-weight: bold;
}

@media only screen and (max-width: 736px) {
  :local .resultTableWrap {
    padding: 10px;
  }
}

:local .table {
  composes: m-table-list from global;
  composes: m-table-fixed from global;
  max-width: 900px;
  margin: 0 auto;
  width: auto !important;
}

:local .table td {
  padding: 10px 10px 5px;
}

:local .notAnsweredTable {
  composes: table;
  margin-top: 67px;
}

@media only screen and (max-width: 736px) {
  :local .notAnsweredTable {
    margin-top: 0;
  }
}

:local .labelColumn {
  width: 212px;
}

@media only screen and (max-width: 736px) {
  :local .labelColumn {
    width: 80px;
  }
}

:local .totalColumn {
  width: 113px;
}

:local .statusColumn {
  width: 113px;
}

:local .row {
  height: 64px;
}

:local .label {
  text-align: left;
  min-width: 80px;
}

:local .childCategoryTitleRow {
  height: 211px;
}

:local .childCategoryTitleCol {
  vertical-align: top;
  letter-spacing: .3em;
  width: 40px;
  padding: 16px 0 0 !important;
}

:local .vertical {
  writing-mode: vertical-rl;
}

:local .withLeftBorder {
  border-left: 1px solid #e0e0e0;
}

:local .average {
  padding: 15px 5px !important;
  font-size: 14px !important;
}

:local .withYellowAlert {
  background-color: #ffea6f;
}

:local .withRedAlert {
  color: #fff;
  background-color: #d0021bcc;
}

:local .descriptionField {
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  display: flex;
}

@media only screen and (max-width: 736px) {
  :local .descriptionField {
    flex-wrap: wrap;
    padding: 10px;
  }
}

:local .description {
  padding-top: 10px;
  padding-left: 20px;
  display: flex;
}

:local .description:first-child {
  padding-left: 0;
}

@media only screen and (max-width: 736px) {
  :local .description {
    flex-basis: 100%;
    padding: 0;
  }
}

:local .yellowRectangle {
  background-color: #ffea6f;
  width: 10px;
  height: 10px;
  margin-top: 3px;
}

:local .redRectangle {
  background-color: #d0021bcc;
  width: 10px;
  height: 10px;
  margin-top: 3px;
}

:local .comment {
  padding-left: 10px;
  font-size: 12px;
}

:local .alertField {
  background-color: #fff;
  max-width: 960px;
  margin-top: 32px;
  margin-bottom: 26px;
  padding: 24px;
}

@media only screen and (max-width: 736px) {
  :local .alertField {
    padding: 20px 10px;
  }
}

:local .alertMain {
  color: #d0021b;
  border: 2px solid #d0021b;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 13px;
  font-weight: bold;
  display: flex;
}

:local .alertMessageField {
  margin-top: 24px;
}

:local .alertMessage {
  color: #d0021b;
  font-size: 14px;
}

:local .noAlert {
  justify-content: center;
  align-items: center;
  font-size: 13px;
  display: flex;
}
