:local .fixation {
  z-index: 100;
  height: calc(100vh - 56px);
  position: fixed;
  top: auto;
}

@media only screen and (max-width: 736px) {
  :local .fixation {
    height: auto;
    position: sticky;
    top: 0;
  }

  :local .fixation > div:first-child {
    max-width: 500px;
    height: 100vh;
  }
}

:local .menuBtn {
  display: none;
}

:local .itemWrap {
  white-space: nowrap;
}

:local .itemRightIcon {
  color: #999;
  vertical-align: middle;
  width: 211px;
  height: 50px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

:local a.itemRightIcon {
  color: #999;
  vertical-align: middle;
  width: 211px;
  height: 50px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

:local .itemRightIcon:hover {
  color: #3498db;
}

:local a.itemRightIcon:hover {
  color: #3498db;
}

:local .itemRightIcon svg {
  margin: 15px;
}

:local a.itemRightIcon svg {
  margin: 15px;
}

@media only screen and (max-width: 736px) {
  :local .menuBtn {
    color: #fff;
    cursor: pointer;
    z-index: 110;
    background-color: #3498db;
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    display: flex;
    position: fixed;
    bottom: 15px;
    left: 15px;
  }

  :local .menuBtn rect {
    fill: #3498db;
  }
}
