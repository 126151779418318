:local .addLmsUserBody {
  width: 610px;
  padding-top: 2px;
  padding-left: 7px;
  padding-right: 3px;
}

@media only screen and (max-width: 736px) {
  :local .addLmsUserBody {
    width: 100%;
    padding: 0;
  }
}

:local .balloon {
  text-align: right;
}

:local .addUserButton {
  min-width: 152px;
}

:local .ul {
  width: 180px;
  height: 50px;
}

:local .addUser {
  background-color: #fff;
  border: none;
}

:local .pulldown {
  margin-right: 10px;
  transform: rotate(-90deg);
}

:local .links {
  flex-direction: column;
}

:local .a {
  color: #666;
  align-items: center;
  padding-bottom: 16px;
  font-size: 13px;
  font-weight: bold;
  display: flex;
}

:local .searchForm {
  display: flex;
}

:local .identifier {
  float: left;
  width: 520px;
}

:local .searchField {
  height: 100%;
}

@media only screen and (max-width: 736px) {
  :local .searchField {
    margin-left: 10px;
  }
}

:local .searchButton {
  height: 38px;
  margin-top: 8px;
  min-width: 80px !important;
}

:local .table {
  composes: m-table-list from global;
  composes: m-table-fixed from global;
  margin-bottom: 33px;
  padding-top: 18px;
  width: 600px !important;
}

:local .table td {
  padding: 10px 10px 5px !important;
}

:local .table td .withLineThrough {
  text-decoration: line-through;
}

:local .emailColumn {
  width: 226px;
}

:local .staffCodeColumn {
  width: 113px;
}

:local .nameColumn {
  width: 148px;
}

:local .roleType {
  width: 110px;
}

:local .row {
  height: 50px;
}

:local .roleTypeField {
  margin-bottom: 20px;
}

:local .buttons {
  justify-content: center;
  display: flex;
}

@media only screen and (max-width: 736px) {
  :local .buttons {
    width: 100%;
  }
}

:local .cancelButton {
  width: 200px;
}

:local .addButton {
  width: 200px;
  margin-left: 20px;
}
