:local .base {
  color: #999;
  background-color: #fff;
  flex-wrap: wrap;
  width: 100%;
  height: 56px;
  padding: 0 30px 20px;
  font-size: 13px;
  display: flex;
  box-shadow: inset 0 1px #ddd;
}

:local .nav {
  flex-wrap: wrap;
  padding-top: 20px;
  display: flex;
}

:local .menu {
  color: #999;
  border-left: 1px solid #999;
  padding: 0 1em;
  text-decoration: none;
}

:local .menu:hover {
  color: #666;
}

:local .menu:first-child {
  border: none;
  padding: 0 1em 0 0;
}

:local .copyright {
  margin-left: auto;
  padding-top: 20px;
}

@media only screen and (max-width: 736px) {
  :local .base {
    height: auto;
    padding: 0 15px 20px;
    display: block;
  }

  :local .nav {
    text-align: center;
    padding-top: 20px;
    display: block;
  }

  :local .menu {
    text-align: center;
    border-left: none;
    padding: 0 10px 8px;
    display: inline-block;
    position: relative;
  }

  :local .menu:after {
    content: "";
    background-color: #ddd;
    width: 1px;
    height: .9em;
    position: absolute;
    top: .2em;
    left: 0;
  }

  :local .menu:first-child:after {
    background-color: #fff;
  }

  :local .copyright {
    text-align: center;
    margin-left: 0;
  }
}
