:local .sortingFieldsWithResult {
  background: #f1f1f1;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  display: flex;
}

@media only screen and (max-width: 736px) {
  :local .sortingFieldsWithResult {
    flex-wrap: wrap;
  }
}

:local .left {
  text-align: left;
  width: 33.3333%;
}

@media only screen and (max-width: 736px) {
  :local .left {
    text-align: center;
    width: 100%;
  }

  :local .left .div {
    min-width: 200px;
  }
}

:local .center {
  text-align: center;
  width: 33.3333%;
}

@media only screen and (max-width: 736px) {
  :local .center {
    width: 100%;
    margin-top: 10px;
  }
}

:local .right {
  width: 33.3333%;
  padding-left: 20px;
}

@media only screen and (max-width: 736px) {
  :local .right {
    width: 100%;
    margin-top: 10px;
    padding-left: 0;
  }

  :local .right div {
    width: 100%;
  }

  :local .right div > div + div {
    margin-left: 10px;
  }
}
