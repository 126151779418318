:local .contentsWrap {
  padding-top: 59px;
}

:local .select {
  width: 560px;
  margin-bottom: 18px;
}

@media only screen and (max-width: 736px) {
  :local .select {
    width: 100%;
  }
}
