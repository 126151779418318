:local .field {
  composes: radio from "jbc-front/components/Form.scss";
  margin-bottom: 63px;
}

:local .label {
  margin-bottom: 11px;
  font-size: 13px;
  font-weight: bold;
}

:local .note {
  color: #777;
  margin-bottom: 25px;
  font-size: 13px;
}

:local .downloadButton {
  margin: 5px;
}
