:local .newMailField {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  max-width: 960px;
  margin: 45px auto 0;
  padding: 40px 75px;
}

@media only screen and (max-width: 736px) {
  :local .newMailField {
    margin-top: 30px;
    padding: 15px 10px;
  }
}

:local .body {
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 8px 10px;
}

:local .description {
  margin-top: 30px;
  margin-bottom: 15px;
}

:local .buttons {
  justify-content: center;
  margin: 50px auto 0;
  display: flex;
}

:local .buttonWrap {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 3px;
  width: 200px;
  height: 40px;
}

:local .backButton {
  color: #666;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

:local .createButton {
  width: 200px;
  margin-left: 20px;
}
